import React from 'react'
import styled from '@emotion/styled'

import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'

const ContentContainer = styled('div')`
  max-width: 1240px;
  margin: 0 auto;
  font-size: 25px;
  padding-top: 150px;
  padding-left: 20px;
  padding-right: 20px;
`

const SecondPage = () => (
  <Layout bgColor="#fff">
    <StickyNav />
    <ContentContainer>
      <h1>Privacy Policy</h1>
      <p>
        Crowdlinker Inc. (“Crowdlinker”/“we”/”us”/“our”) is committed to
        protecting your privacy. Crowdlinker owns and operates the website
        located at https://crowdlinker.com/ (the “Website”). This Privacy Policy
        describes how we collect, store, use and distribute information about
        viewers of the Website.
      </p>
      <h4>
        <strong>CONSENT:</strong>
      </h4>
      <p>
        <strong>
          By accessing the Website you consent to the use of your Personal
          Information as described in this Privacy Policy
        </strong>
        . Except as set forth in this Privacy Policy, your Personal Information
        will not be used for any other purpose without your consent. You may
        withdraw your consent to our processing of your Personal Information at
        any time. However, withdrawing consent may result in your inability to
        continue using the Website.
      </p>
      <h4>
        <strong>COLLECTION OF INFORMATION:</strong>
      </h4>
      <p>
        We aim to collect, use and disclose only such information as is required
        to enable us to respond to your inquiries, provide you with information
        you request and assess any job applications you submit. We will maintain
        the confidentiality of any contact information you provide to us and we
        will use it only for the purposes for which we have collected it
        (subject to the exclusions and disclosures we have listed below), unless
        you agree that we may disclose it to other third parties.
      </p>
      <p>
        Two types of information may be collected through the Website: Personal
        Information and Non-Personal Information. This Privacy Policy does not
        extend to the collection, use or disclosure of the following information
        which is currently not limited by applicable privacy laws: (a)
        information that is publicly available, such as names, addresses,
        telephone numbers and electronic address when listed in a directory or
        made available through directory assistance; or (b) Non-Personal
        Information (as defined further below).
      </p>
      <p>
        “
        <strong>
          <em>Personal Information</em>
        </strong>
        ” is personally identifiable information, such as your name, address,
        e-mail address, credit card information, birth date and gender. At the
        time of collection, we will clearly identify the information being
        collected and the purposes for which it will be used. It is always your
        choice whether or not to provide Personal Information but if you choose
        not to provide certain requested Personal Information, in some instances
        you may not be able to use certain features of the Website.
      </p>
      <p>
        “
        <strong>
          <em>Non-Personal Information</em>
        </strong>
        ” is information of an anonymous nature, such as an Internet Protocol
        Address (IP Address), the domain used to access the Website, and the
        type and version of browser or operating system being used by visitors
        to access the Website.
      </p>
      <p>
        Aggregate information, such as number of visitors, what pages viewers
        access or visit, and average time spent on the Website is not considered
        Personal Information. Similarly, business contact information such as
        the name, title, business address, e-mail address, or telephone number
        of a business or professional person or an employee of an organization
        is not considered Personal Information.
      </p>
      <p>
        Although the use of certain Non-Personal Information collected, used or
        disclosed through the Internet as described herein is not restricted
        (and to the extent that such is the case, the obligations under this
        Privacy Policy do not apply to such information), we provide information
        in this Privacy Policy about the collection of such information for the
        sake of transparency with respect to the operation of the Website. Such
        Non-Personal Information is collected or derived by us in the course of
        operating this Website. For example, our servers may automatically
        collect Non-Personal Information that is provided through your browser
        or stored in a cookie.
      </p>
      <h4>
        <strong>USE OF INFORMATION:</strong>
      </h4>
      <p>We collect information for the following purposes:</p>
      <ul>
        <li>
          <u>Request a Demo</u>: If you would like to request a demo of our
          products or services, you may do so by providing us with your full
          name, email address, phone number, company name and your role in the
          ‘Request a Demo’ field on our Website. We will use this information to
          contact you and discuss the possibility of providing you with a demo.
        </li>
        <li>
          <u>Contact Us</u>: If you use the ‘Contact Us’ form on the Website, we
          will collect your name, email address, subject line and the content of
          your message. We will use this information to contact you in order to
          respond to your inquiry.
        </li>
        <li>
          <u>Newsletter</u>: You can choose to opt-in to our newsletter by
          providing us with your first name and your email address. If you
          opt-in to receive our newsletter, we will keep you up to date on our
          products and services via the email address you have provided to us.
          You may withdraw your consent to receiving our newsletter at any time
          by following the opt-out instructions in each email communication.
        </li>
        <li>
          <u>Job Applications</u>: If you submit a job application to us, we
          will collect all of the Personal Information that you provide to us
          such as your name, contact information and resume. We will use this
          information to assess your candidacy for a position with us and to
          contact you if we determine we would like to obtain further
          information from you or invite you to an interview.
        </li>
        <li>
          <u>Statistics</u>: We collect statistics about use of the Website
          which we use for analytics including predictive analytics. Aggregate
          statistics that do not personally identify an individual will be kept
          by us and such aggregate statistics may be made available to other
          members or third parties.
        </li>
        <li>
          <u>System Logs &amp; Cookies</u>: Cookies are used by us to track
          content usage and traffic on the Website. A cookie is a feature of
          your web browser that consists of a text file that is placed on your
          hard disk by a web server. The Website uses cookies to help it compile
          aggregate statistics about usage of this Website, such as how many
          users visit the Website, how long users spend viewing the Website, and
          what pages are viewed most often. This information is used to improve
          the content of the Website. You can set your browser to notify you
          when you are sent a cookie. This gives you the chance to decide
          whether or not to accept it. If you disable cookies, you may not be
          able to take advantage of all the features of the Website.
        </li>
      </ul>
      <p>
        Your IP address is reported by your web browser whenever you visit a
        page on the Website. This information is recorded together with your
        registration information on our databases.
      </p>
      <ul>
        <li>
          <u>Third-Party Links</u>: The Website may contain links to other
          third-party websites that are not owned or controlled by us. Such
          third-party websites are governed by the terms and conditions and
          privacy policies of such third-party providers and we are not involved
          in any interaction or transaction between you and such third-parties.
        </li>
      </ul>
      <p>
        If we plan to use your Personal Information in future for any other
        purposes not identified above, we will only do so after informing you by
        updating this Privacy Policy. See further the section of this Privacy
        Policy entitled ‘Amendment of this Policy’.
      </p>
      <h4>DISCLOSURES &amp; TRANSFERS:</h4>
      <p>
        We have put in place contractual and other organizational safeguards
        with our agents (see further below) to ensure a proper level of
        protection of your Personal Information (see further Security below). In
        addition to those measures, we will not disclose or transfer your
        Personal Information to third parties without your permission, except as
        specified in this Privacy Policy (see further Important Exceptions
        below).
      </p>
      <p>
        As at the date of this Privacy Policy, we share Personal Information
        about you in respect of the Website only with our service providers and
        partners which include website hosting, butt service providers,
        analytics providers, email marketing providers and customer support
        providers. Our service providers are located in Canada and the Untied
        States and accordingly your Personal Information may be available to
        Canadian and United States governments under a lawful order,
        irrespective of the safeguards we have put in place for the protection
        of your Personal Information.
      </p>
      <p>
        From time to time we may employ third parties to help us improve the
        Website. These third parties may have limited access to databases of
        user information solely for the purpose of helping us to improve the
        Website and they will be subject to contractual restrictions prohibiting
        them from using the information about our members for any other purpose.
      </p>
      <p>
        <strong>
          <em>Important Exceptions</em>
        </strong>
        : We may disclose your Personal Information to third parties without
        your consent if we have reason to believe that disclosing this
        information is necessary to identify, contact or bring legal action
        against someone who may be causing injury to or interference with
        (either intentionally or unintentionally) our rights or property, other
        Website viewers, or anyone else (including the rights or property of
        anyone else) that could be harmed by such activities. We may disclose
        Personal Information when we believe in good faith that such disclosure
        is required by and in accordance with the law.
      </p>
      <p>
        We may also disclose your Personal Information in connection with a
        corporate re-organization, a merger or amalgamation with another entity,
        a sale of all or a substantial portion of our assets or stock, including
        any due diligence exercise carried out in relation to the same, provided
        that the information disclosed continues to be used for the purposes
        permitted by this Privacy Policy by the entity acquiring the
        information.
      </p>
      <h4>
        <strong>SECURITY:</strong>
      </h4>
      <p>
        The security of your Personal Information is important to us. We use
        commercially reasonable efforts to store and maintain your Personal
        Information in a secure environment. We take technical, contractual,
        administrative, and physical security steps designed to protect Personal
        Information that you provide to us. We have implemented procedures
        designed to limit the dissemination of your Personal Information to only
        such designated staff as are reasonably necessary to carry out the
        stated purposes we have communicated to you.
      </p>
      <h4>
        <strong>RETENTION:</strong>
      </h4>
      <p>
        We will keep your Personal Information for as long as it remains
        necessary for the identified purpose or as required by law, which may
        extend beyond the termination of our relationship with you. We may
        retain certain data as necessary to prevent fraud or future abuse, or
        for legitimate business purposes, such as analysis of aggregated,
        non-personally-identifiable data, account recovery, or if required by
        law. All retained personal information will remain subject to the terms
        of this Privacy Policy. If you request that your information be removed
        from our databases, it may not be possible to completely delete all your
        Personal Information due to technological and legal constraints.
      </p>
      <h4>
        <strong>AMENDMENT OF THIS POLICY:</strong>
      </h4>
      <p>
        We reserve the right to change this Privacy Policy at any time. If we
        decide to change this Privacy Policy in the future, we will post an
        appropriate notice on the home page. Any non-material change (such as
        clarifications) to this Privacy Policy will become effective on the date
        the change is posted and any material changes will become effective 30
        days from their posting on the Website. Unless stated otherwise, our
        current Privacy Policy applies to all Personal Information that we have
        about you. The date on which the latest update was made is indicated at
        the bottom of this document. We recommend that you print a copy of this
        Privacy Policy for your reference and revisit this policy from time to
        time to ensure you are aware of any changes. Your continued use of the
        Website signifies your acceptance of any changes.
      </p>
      <h4>
        <strong>ACCESS AND ACCURACY:</strong>
      </h4>
      <p>
        You have the right to access the Personal Information we hold about you
        in order to verify the Personal Information we have collected in respect
        to you and to have a general account of our uses of that information.
        Upon receipt of your written request, we will provide you with a copy of
        your Personal Information although in certain limited circumstances, we
        may not be able to make all relevant information available to you such
        as where that information also pertains to another user. In such
        circumstances we will provide reasons for the denial to you upon
        request. We will endeavor to deal with all requests for access and
        modifications in a timely manner.
      </p>
      <p>
        We will make every reasonable effort to keep your Personal Information
        accurate and up-to-date, and we will provide you with mechanisms to
        update, correct, delete or add to your Personal Information as
        appropriate. As appropriate, this amended Personal Information will be
        transmitted to those parties to which we are permitted to disclose your
        information. Having accurate Personal Information about you enables us
        to give you the best possible service.
      </p>
      <h4>
        <strong>CONTACT US:</strong>
      </h4>
      <p>
        You can help by keeping us informed of any changes such as a change of
        address or telephone number. If you would like to access your
        information, if you have any questions, comments or suggestions of if
        you find any errors in our information about you, please contact us at:
        <a href="mailto:hello@crowdlinker.com">
          hello@crowdlinker.com
          <br />
        </a>
      </p>
      <br />
      <p>
        <strong>Last Updated: January 27, 2015</strong>
      </p>
    </ContentContainer>
  </Layout>
)

export default SecondPage
